.star {
    width: 25px;
    height: 25px;
    color: #ccc;

    &:hover {
        color: #ffe766;
    }

    &.active {
        color: #ffd700;

        &:hover {
            color: #e0e0e0
        }
    }
}