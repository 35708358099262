.editable-element {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    min-height: 40px;
    padding: 10px 12px;
    cursor: grab;
    width: 100%;

    &.uneditable {
        cursor: default;
    }

    .editable__icon {
        width: 12px;
        height: 12px;
        position: absolute;
        z-index: 5;
        right: 2px;
        top: 0;
        cursor: default;
        &:hover {
            animation: pulse 5s ease-in-out infinite;
        }
        /* transform: translateX(-50%) ; */
    }

    .editable-elemnt__value {
        display: inline-block;
        width: 100%;
        margin: 0;
        cursor: pointer;

        &.none {
            /* display: none; */
            visibility: hidden;
        }
    }

    .editable-element__input {
        position: absolute;
        top: 3px;
        bottom: 3px;
        background: transparent;
        border: none;
        color: black;
        height: auto;
        width: 95%;
        padding: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        z-index: 11;
    }
}

.hidden {
    height: 0;
    max-height: 0;
    min-height: 0;
    padding: 0;
    min-height: 0;
    overflow: hidden;
}

@keyframes pulse {
    0% {
        color: rgb(0, 0, 0)
    }

    25% {
        color: var(--lukoil-red);
    }

    50% {
        color: rgb(50, 178, 50);
    }

    75% {
        color: #1890ff;
    }
}