.header {

    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-block: 10px;

    &-container {
        display: flex;
        width: 100%;
        // justify-content: space-between;
        gap: 20px;
        align-items: flex-end;
        padding: 10px 0;

        .logo-container {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
        }
    }

    &__module {
        font-size: 16px;
        color: #4B5768;
        font-weight: 600;
        line-height: 1;
    }

    &__slider {
        margin-block: 12px 16px;
    }
}

.burger-menu-container {
    margin: 0;
    padding: 0;
    user-select: none;

    ul {
        padding: 0;
        margin: 0;
    }
    li {
        list-style: none;
        padding: 7px 0;
        color: #575757;
        cursor: pointer;
        &:hover {
            color: black;
        }
    }
}

.exit-icon {
    cursor: pointer;
    margin: 5px 5px 0 5px;
}

.event-page-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.favourites {
    border: 2px dashed black;
    padding: 8px 5px;
    margin-bottom: -8px;
    align-items: center;
}