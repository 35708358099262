@import "../styles/commonStyles.scss";

:root {
    --toastify-color-error: green;
    --lukoil-red: #e22739;
    --indigo-200: #f3f4f7;
    --indigo-900: #2d3443;
}

.App {
    text-align: center;
}

* {
    font-family: "ProximaNova" !important;
}

input {
    border-radius: 10px;
    border: 1px solid lightgray;
    min-height: 30px;
    padding: 10px;
    color: #7d899a;
    background-color: #f9f9f9;

    &.search {
        height: 52px;
    }
}

.modal__button {
    width: calc(100% - 40px);
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 20px 20px;
}

:where(.css-dev-only-do-not-override-zg0ahe).ant-select-single {
    font-size: 16px;

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
        font-size: 16px;
        font-weight: 500;
    }
}

.editable-select.ant-select-outlined:not(.ant-select-customize-input) {
    width: 100%;
    padding: 0;
    border-radius: 10px;
    height: 52px;
    background-color: #f9f9f9;
    color: #7d899a;

    &.ant-select-disabled .ant-select-selector {
        background-color: #e0e0e0;
    }

    &:hover .ant-select-selector {
        border-color: lightgray !important;
    }

    option {
        border-radius: 10px;
        padding: 10px;
        height: 52px;
    }
}

:where(.css-dev-only-do-not-override-1cmt6il).ant-radio-group {
    user-select: none;
    * {
        user-select: none;
    }
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #ed1a3b !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #ed1a3b !important;
}

.editable-cell {
    &__select {
        width: 100%;
        min-height: 32px;
        height: auto !important;

        .ant-select-selection-item {
            white-space: normal;
        }
    }

    &__dropdown {
        * {
            white-space: normal !important;
        }
    }
}

.hurricane-label {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
}
