.hurricane-select.ant-select-outlined:not(.ant-select-customize-input) {
    padding: 0;
    border-radius: 10px;
    height: 52px;
    background-color: #f9f9f9;
    color: #7d899a;
    width: 100%;

    &.ant-select-disabled .ant-select-selector {
        background-color: #e0e0e0;
    }

    &:where(:hover, .ant-select-focused) .ant-select-selector {
        border-color: #d9d9d9 !important;
        box-shadow: 0 0 0 2px #f4f4f4 !important;
    }

    &.lukoil:where(:hover, .ant-select-focused) .ant-select-selector {
        border-color: #e22739 !important;
        box-shadow: 0 0 0 2px #f9d4d7 !important;
    }

    option {
        border-radius: 10px;
        padding: 10px;
        height: 52px;
    }
}
