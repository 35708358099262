.font {
    &__button {
        font-size: 14px;
        border: none;
        padding: 5px;
        cursor: pointer;
        text-decoration: none;
        background: transparent;
        color: black;

        &:hover {
            background-color: transparent !important;
        }
    }

    &__label {
        font-weight: bold;
        font-size: 14px;
        text-align: center;
    }

    &__input {
        width: 220px;
        height: 44px;
        border: 1px solid #ccc;
        font-size: 14px;
        text-align: center;
    }
}
