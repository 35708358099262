.excel-table {
    padding: 0;
    margin-inline: 0;
    margin-bottom: 0;
    table-layout: fixed;
    margin-left: 18px;
}

.excel-table-container {
    overflow-x: overlay;
    position: relative;
    overflow-y: auto;
    /* padding-left: 18px; */
    margin-left: -18px;

    /* margin-bottom: 8px; */
    &::-webkit-scrollbar {
        width: 12px;
        /* ширина scrollbar */
        height: 12px;
        position: absolute;
        bottom: 50px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        /* цвет дорожки */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        /* цвет плашки */
        border-radius: 30px;
        /* закругления плашки */
        /* border: 3px solid orange; padding вокруг плашки */
        position: absolute;
        bottom: 50px;
    }
}